<template>
  <v-container
    fluid
    fill-height
    pa-2
    class="conteneur-page gradient-bleu align-start heritage-scroll flex-row"
  >
    <v-col
      v-if="!loading && $vuetify.breakpoint.smAndUp"
      class="pa-0 pr-1 heritage-scroll "
    >
      <v-card class="heritage-scroll flex-column flex-grow-1">
        <v-col>
          <v-select
            v-if="listEntreprise.length > 1"
            v-model="idEntreprise"
            :test-auto="nomPage + '_entreprise'"
            hide-details
            label="Entreprise*"
            required
            :items="listEntreprise"
            item-text="nomEntreprise"
            item-value="idEntreprise"
          ></v-select>

          <v-card-title>
            <span class="headline">Nom de l'Entreprise : </span>
            <span class="headline info-entreprise">{{
              EntreprisesSelectionnees.nomEntreprise
            }}</span>

            <v-btn
              class="ml-4 gradient-bleu"
              dark
              @click=" redirigerVersExport() "
            >
              Export Codex
            </v-btn>
          </v-card-title>
          <v-card-title>
            <span class="headline">Pointage à l'heure : </span>
            <v-switch
              v-model="EntreprisesSelectionnees.autoriserPointageAHeure"
              class="info-entreprise"
              :test-auto="nomPage + '_autoriserPointageAHeure'"
              inset
              color="green"
              :label="
                EntreprisesSelectionnees.autoriserPointageAHeure
                  ? 'Pointage à l\'heure activé'
                  : 'Pointage à l\'heure désactivé'
              "
              @click="updatePointageALHeure()"
            ></v-switch>
          </v-card-title>
          <v-card-title>
            <span class="headline">Profils : </span>
            <v-simple-table class="info-entreprise tableau_role">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="titre">
                      <div class="center">
                        Role
                      </div>
                    </th>
                    <th class="titre">
                      <div class="center">
                        temps Travaillé
                      </div>
                    </th>
                    <th class="titre">
                      <div class="center">
                        Niveau de Rôle
                      </div>
                    </th>
                    <th class="titre">
                      <div class="center">
                        Catégorie absence/ présence
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in profilsEntreprisesSelectionnees"
                    :key="item.idRole"
                  >
                    <td>
                      <div :class="'center role role-id-' + item.idRole">
                        {{ roles[item.idRole].nomRole }}
                      </div>
                    </td>
                    <td>
                      <div :class="'center'">
                        {{ minutesToHeureFormater(item.tempsTravaille * 60) }}
                      </div>
                    </td>
                    <td>
                      <div sclass="center">
                        <v-text-field
                          v-model="item.niveauRole"
                          dense
                          :test-auto="nomPage + '_niveauRole'"
                          hide-details
                          class="centered-input text--darken-3 mt-3"
                          min="5"
                          max="50"
                          type="number"
                          @change="updateProfil(item)"
                        >
                        </v-text-field>
                      </div>
                    </td>
                    <td>
                      <div sclass="center">
                        <v-text-field
                          v-model="item.categorieAbsencePresence"
                          dense
                          :test-auto="nomPage + '_categorieAbsencePresence'"
                          hide-details
                          class="centered-input text--darken-3 mt-3"
                          min="5"
                          max="50"
                          type="text"
                          @change="updateProfil(item)"
                        >
                        </v-text-field>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-title>
        </v-col>
      </v-card>
    </v-col>
  </v-container>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { minutesToHeureFormater } from "@/utils/transform";
export default {
  components: {},
  data: () => ({
    loading: false,
    saving: false,
    nomPage: "entreprise",
    idEntreprise: null,
    EntreprisesSelectionnees: {},
    profilsEntreprisesSelectionnees: [],
  }),
  computed: {
    ...mapState("entreprise", ["entreprises", "profils", "roles"]),
    ...mapState("utilisateur", ["utilisateur"]),
    ...mapGetters("utilisateur", [
      "ListRessourceToEntrepriseIdList",
      "listEntreprise",
    ]),
  },
  watch: {
    idEntreprise: function() {
      this.EntreprisesSelectionnees = { ...this.entreprises[this.idEntreprise] };
      let copyProfils = [];
      //on copie object par objet car le {... } ne copie pas en profondeur
      //ce qui ammment des erreur lier au mutaion vuex hors du state car copie l'objet
      //en lui meme et pas les sous object
      for (const key in this.profils) {
        copyProfils.push({ ...this.profils[key] });
      }
      this.profilsEntreprisesSelectionnees = copyProfils
        .filter((profil) => profil.idEntreprise === this.idEntreprise)
        .sort((a, b) =>
          this.roles[a.idRole].nomRole.localeCompare(
            this.roles[b.idRole].nomRole
          )
        );
    },
  },
  created() {
    this.idEntreprise = this.utilisateur.idEntreprise;
    this.EntreprisesSelectionnees = { ...this.entreprises[this.idEntreprise] };
  },
  mounted() {
    //
  },
  methods: {
    ...mapActions("entreprise", ["updateEntreprise", "updateProfil","setEntrepriseSelectionnee","setUtilisateursByEntrepriseSelectionnee"]),
    minutesToHeureFormater: minutesToHeureFormater,
    updatePointageALHeure() {
      //console.log(this.EntreprisesSelectionnees);
      this.updateEntreprise(this.EntreprisesSelectionnees);
    },
    updateNiveauDeRole(profil) {
      this.updateProfil(profil);
    },
    redirigerVersExport() {
      this.setEntrepriseSelectionnee(this.EntreprisesSelectionnees);
      this.setUtilisateursByEntrepriseSelectionnee();
      this.$router.push("/export");
    },
  },
};
</script>
<style scoped>
.info-entreprise {
  margin-left: auto;
  margin-right: auto;
}
.tableau_role {
  width: 45%;
}
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.role {
  padding: 10px 10px 10px 10px;
  width: 100px;
  border-radius: 20px;
  color: white;
}
.titre {
  text-align: center;
}
.centered-input >>> input {
  text-align: center;
}
</style>
